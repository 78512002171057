<template>
  <div class="all_main_wrap">
    <HeaderBox>
      <template #title>
        证书模版
      </template>
      <template #input>
        <a-button class="all_boder_btn" @click="state = 1,visible = true">新建模版</a-button>
      </template>
    </HeaderBox>


    <!-- 表格列表 -->
    <div class="table-box">
      <a-tabs @change="callback">
        <a-tab-pane key="1" tab="中康协认证证书"></a-tab-pane>
        <a-tab-pane key="5" tab="CEU学分证明"></a-tab-pane>
        <a-tab-pane key="6" tab="自研课程证书"></a-tab-pane>
        <a-tab-pane key="2" tab="课程证书"></a-tab-pane>
      </a-tabs>

      <a-table
        class="table-template"
        :rowKey="item=>item.tempId"
        :columns="columns"
        :loading="loading"
        :data-source="tableData"
        @change="onPage"
        :pagination="{
          total:total,
          current:queryParams.pageNum,  
          defaultPageSize:queryParams.pageSize, 
          showSizeChanger: true,
          showTotal: function(total, range){
          return `共${total}条`}} ">
        <template slot="index" slot-scope="item, row, i">
          <div style="text-align: center;">
            {{ (queryParams.pageNum - 1) * queryParams.pageSize + i + 1 }}
          </div>
        </template>

        <!-- 使用状态 -->
        <template slot="status" slot-scope="item">
          <span class="state_all" :class="item ? 'state_color_green' : 'state_color_red'">{{item ? '已启用' : '已停用'}}</span>
        </template>

        <template slot="operation" slot-scope="item">
          <div class="btn_router_link">
            <a @click="onEnable(item)">{{item.status ? '停用' : '启用'}}</a>
            <span>|</span>
            <a @click="state=2;visible=true,onEdit(item)">编辑</a>
            <span>|</span>
            <a @click="deleteData(item)">删除</a>
          </div>
        </template>
      </a-table>
    </div>
    <!-- 新建/编辑 -->
    <a-modal v-model="visible" okText="确认" :confirm-loading="PreventLoad" @ok="onSaveForm" :title="(state==1?'新建':'编辑')+'证书模版'" width="700px">
      <a-row class="rows">
        <a-col span="4" class="left">模版名称</a-col>
        <a-col span="10" class="right">
          <a-input v-model="form.name" placeholder="请输入模板名称" />
        </a-col>
      </a-row>
      <a-row class="rows">
        <a-col span="4" class="left">选择模版</a-col>
        <a-col span="19" class="right">
          <a-radio-group class="radio" v-model="form.url">
            <a-radio :value="item.pictureId" class="radioItem" v-for="item in pictureData" :key="item.pictureId">
              <viewer> 
               <img :src="item.picture" alt="">
              </viewer>
            </a-radio>
          </a-radio-group>
        </a-col>
      </a-row>
      <a-row class="rows">
        <a-col span="4" class="left">适用课程</a-col>
        <a-col span="10" class="right">
          <a-select style="width:100%;" placeholder="请选择适用课程" v-model="form.courseId">
            <a-icon slot="suffixIcon" type="caret-down" />
            <a-select-option v-for="item in courseData" :key="item.courseId" :value="item.courseId">{{item.courseName}}</a-select-option>
          </a-select>
        </a-col>
      </a-row>
      <a-row class="rows">
        <!-- <a-col span="5" class="left">启用方式</a-col> -->
        <a-col span="10" class="right flex" offset="4">
          <a-radio-group 
          v-model="form.status">
            <a-radio :value="0">暂不启用</a-radio>
            <a-radio :value="1">提交即启用</a-radio>
          </a-radio-group>
        </a-col>
      </a-row>
    </a-modal>
  </div>
</template>
 
<script>
import HeaderBox from '@/components/HeaderBox.vue'
const columns = [
  {
    title: "序号",
    width: "80px",
    align:'center',
    dataIndex: "index",
    scopedSlots: { customRender: 'index' }

  },
  {
    title: "模版名称",
    align:'center',
    dataIndex: "name"
  },
  {
    title: "课程名称",
    align:'center',
    dataIndex: "courseName"
  },
  {
    title: "考试名称",
    align:'center',
    dataIndex: "examName"
  },
  {
    title: "状态",
    align:'center',
    dataIndex: "status",
    scopedSlots: { customRender: "status" }

  },
  {
    title: "操作",
    align:'center',
    width: "160px",
    scopedSlots: { customRender: "operation" }
  }
];
export default {
  // 可用组件的哈希表
  components: { HeaderBox },
  // 接收传值
  props: {},
  // 数据对象
  data () {
    return {
      state: 1, // 1新建，2编辑
      visible: false,
      columns, // 表头
      tableData:[], // 列表数据
      pictureData:[], // 图片数据
      courseData:[], // 课程列表
      loading:false, // 列表加载
      total: 0, // 总数量
      templateType: '1', // 模版类型


      queryParams:{
        type: 1,  // 模板类型 1.考试模板 2.课程模板
        pageNum: 1,
        pageSize: 10,
      },
      form:{
        tempId:'', 
        courseId: undefined,  // 课程id
        name: "",  // 模板名称
        status: 1,   // 模板状态 1.启用 0.停用
        type: 1,  // 模板类型
        url: ''
      },
      PreventLoad: false
    }
  },
  // 事件处理器
  methods: {
    // 分页
    onPage(e){
      this.queryParams.pageNum = e.current
      this.queryParams.pageSize = e.pageSize
      this.getManageList()
    },
    // tab切换
    callback(e){
      this.queryParams.type = e
      this.form.type = e
      this.getManageList()
      this.getPictureList()
    },
    onEdit(e){
      this.$ajax({
        method: 'get',
        url:"/hxclass-management/certif-temp/" + e.tempId,
      }).then(res => {
        if (res.code == 200 && res.success) {
          this.form=res.data
          this.form.url = parseInt(res.data.url)
        }
      })
    },
    // 保存证书模板
    onSaveForm(){
      if(!this.form.name){
        this.$message.warning('请填写模板名称');
        return
      }
      if(!this.form.url){
        this.$message.warning('请选择模板');
        return
      }
      if(!this.form.courseId){
        this.$message.warning('请选择适用课程');
        return
      }
      this.PreventLoad = true;
      this.$ajax({
        method: this.form.tempId ? 'PUT' : 'post',
        url:this.form.tempId ? '/hxclass-management/certif-temp/' + this.form.tempId : '/hxclass-management/certif-temp/',
        params: this.form
      }).then(res => {
        if (res.code == 200 && res.success) {
          this.visible = false
          this.getManageList()
          this.$message.success(res.message);
        }else{
          this.$message.warning(res.message);
        }
      }).catch(error => {
        this.loading = false
        if (error) {
          this.$message.warning('系统错误');
        }
      })
      this.PreventLoad = false;
    },
    // 查询列表
    getManageList(){
      this.loading = true
      this.$ajax({
        method: 'get',
        url:"/hxclass-management/certif-temp/",
        params: this.queryParams
      }).then(res => {
        this.loading = false
        if (res.code == 200 && res.success) {
          this.tableData = res.data.records
          this.total = res.data.total
        } else {
          this.tableData = []
          this.total = 0
        }
      }).catch(error => {
        this.loading = false
        if (error) {
          this.$message.warning('系统错误');
        }
      })
    },
    // 查询图片模板
    getPictureList(){
      this.$ajax({
        method: 'get',
        url:"/hxclass-management/certif-temp/choose/picture",
        params: {
          type: this.queryParams.type
        }
      }).then(res => {
        if (res.code == 200 && res.success) {
          this.pictureData = res.data
        }
      })
    },

    // 查询课程列表---下拉框
    getCourseList(){
      this.$ajax({
        method: 'get',
        url:"/hxclass-management/course/select",
      }).then(res => {
        if (res.code == 200 && res.success) {
          this.courseData = res.data
        }
      })
    },
    
    // 停用/启用
    onEnable(e){
      if(e.useStateTxt == '使用中'){
        this.$message.warning('该证书已被使用，无法修改！');
        return
      }
      this.$ajax({
        method: 'PUT',
        url: '/hxclass-management/certif-temp/' + e.tempId + '/' + (e.status ? 0 : 1)
      }).then(res => {
        if (res.code == 200 && res.success) {
          this.getManageList()
        } else{
          this.$message.warning(res.message);
        }
      })
    },
    // 删除模板
    deleteData(e) {
      if(e.useStateTxt == '使用中'){
        this.$message.warning('该证书已被使用，无法删除！');
        return
      }
      let _this = this
      this.$confirm({
        title: '确定删除该数据吗?',
        okText: '确认',
        onOk() {
          _this.$ajax({
            url: '/hxclass-management/certif-temp/' + e.tempId,
            method: "DELETE",
          }).then((res) => {
            if (res.code == 200 && res.success) {
              _this.$message.success('删除成功');
              _this.getManageList()
            }
          })
        },
        onCancel() {},
      });
    },
  },
  // 生命周期-实例创建完成后调用
  created () {
    this.getManageList()  // 证书模板列表
  },
  // 生命周期-实例挂载后调用
  mounted () { 
    this.getPictureList()  // 证书图片列表
    this.getCourseList()  // 课程列表
  },
  // 生命周期-实例销毁离开后调用
  destroyed () { },
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {
    visible(){
      if(!this.visible){
        this.form.tempId = ''
        this.form.courseId = undefined
        this.form.status = 1
        this.form.name = ""
        this.form.url = ""
      }
    }
  }
}
</script>

<style lang="less" scoped>
.btn{
  margin-right: 24px;
}
.table-box{
  /deep/ .ant-tabs-bar{
    border-bottom:none
  }
}
.table-template{
  margin-top: 0;
}
.table {
  /deep/.ant-table-thead > tr > th {
    background: #f6f6fc;
  }
  /deep/.ant-table-footer {
    background: #f6f6fc;
    padding: 12px 16px;
  }
  /deep/.ant-table-thead > tr > th,
  /deep/.ant-table-tbody > tr > td {
    padding: 12px 16px;
  }
  /deep/.ant-table {
    border: 1px solid #eeeeee;
    box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.1);
  }
  th.column-money,
  td.column-money {
    text-align: right !important;
  }
  .operation {
    color: #3681f0;
  }
  .allSellect{
    padding-left: 6px;
  }
}
.rows{
  margin-bottom: 20px;
  &:last-child{
    margin-bottom: 0;
  }
  .left{
    text-align: right;
    height: 32px;
    line-height: 32px;
    &::before{
      content: '*';
      color: red;
      margin-right: 2px;
    }
    &::after{
      content: '：';
    }
  }
  .flex{
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 32px;
    input{
      flex: 1;
    }
    a{
      margin-left: 8px;
    }
  }
}
.radio{
  display: flex;
  flex-direction: row;
  max-width: 500px;
  overflow-x: auto;
  overflow-y: hidden;
  .radioItem{
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    margin-bottom: 10px;
    img{
      max-width: 100px;
      max-height: 120px;
      margin-bottom: 10px;
    }
  }
}
.radio::-webkit-scrollbar {
  height: 6px;
  width: 15px;
}
.radio::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0.1);
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
}
.radio::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.3);
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
}
</style>
